import React, { useContext, useEffect } from 'react';
import Notifications from 'react-notification-system-redux';
import Billboard from '../Components/Commons/Billboard';
import { MessagesQueue } from '../Components/Messages';
import Footer from '../Components/Commons/Footer';
import Nps from '../Components/Nps';
import { useIntl } from 'react-intl';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TermsAndConditionsUpdate from '../Components/Consent/TermAndConditionsUpdate';
import { Redirect } from 'react-router-dom';
import { AdBlockContext } from '../contexts/AdBlock';
import { GlobalProvider } from '../contexts/GlobalContext';
import { AdsProvider } from '../contexts/AdsContext';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';
import AdSenseWrapper from '../Components/Ads/AdSenseWrapper';
import MuiToolbar from '../Components/Commons/MuiToolbar';
import { useMediaQuery, makeStyles, useTheme } from '@material-ui/core';
import { SmallAd } from '../Components/Ads/SmallAds/SmallAd';
import { hideFreshworksWidget, showFreshworksWidget } from './../shared/Freshdesk';

export const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '@media (min-width: 992px)': {
      marginLeft: drawerWidth,
    }
  },
}));

export const PrivateTemplate = ({children}) => {
  const theme = useTheme();
  const classes = useStyles()
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const location = useLocation();
  const loggedIn = useSelector(state => state.security.loggedIn)
  const user = useSelector(state => state.user.user);
  const notifications = useSelector(state => state.notifications);
  const { adBlockDetected } = useContext(AdBlockContext);
  const intl = useIntl();

  useEffect(() => {
    if (location.pathname.includes("/ai")) {
      hideFreshworksWidget()
    }
    else {
      showFreshworksWidget()
    }
  }, [])
  
  if (adBlockDetected === true && location.pathname !== "/adblock") {
    return (
      <Redirect to="/adblock" />
    );
  }

  if (loggedIn === false) {
    return (
      <Redirect to="/login" />
    );
  }

  return (
    <GlobalProvider>
      <AdsProvider>
      <Helmet htmlAttributes={{lang: intl.locale}} />
      <Notifications
        notifications={notifications}
        style={false}
      />

      <AdSenseWrapper adSlot="3243012252" responsive='true' className="billboard-adsense">
        <Billboard show={loggedIn}/>
      </AdSenseWrapper>
      <MuiToolbar location={location} />
      <div className="container-fluid content main-content" style={{"display": "flex"}}>


          <div className={`${clsx(classes.content, {
          [classes.contentShift]: lg, })} d-flex flex-column justify-content-between`} style={{paddingTop: "75px"}}>
            <div>
              <MessagesQueue />
              {children}
            </div>

              { lg ? null : 
              <div className='text-center mb-4 mt-2'>
                <AdSenseWrapper adSlot="8102201663" className="small-adsense" smallAd={true}>
                  <SmallAd className={'justify-content-center p-2'}/>
                </AdSenseWrapper>
              </div>}
          </div>
      </div>

      <Footer />

      <Nps />

      {user?.askForTermsAndConditions === true ? <TermsAndConditionsUpdate /> : null}
      
      </AdsProvider>
    </GlobalProvider>

  );

};

const MenuItem = ({location, path, children, dataTest}) => {
  return (

    <Link data-test={dataTest} to={path} className={`px-2 py-1 d-flex align-items-center flex-wrap${isMenuItemActive(location, path) ? ' active' : ''}`}>
      {children}
    </Link>

  );
}

const isMenuItemActive = (location, path) => {
  return matchPath(location.pathname, {
    "path": path,
    "exact": true,
    "strict": true
  });
}
