import React, { useCallback, useEffect, useState } from "react";
import { BaseFlashcard } from "./BaseFlashcard";
import "./BaseFlashcardArray.css";
import { Grid, LinearProgress } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { remInPixels } from "../../../shared/utils";
import { useHistory, useRouteMatch } from "react-router-dom";


export const BaseFlashcardArray = ({
  cards,
  FlashcardArrayStyle = {},
  onCardChange = () => {},
  onCardFlip = () => {},
  onLastFlashcard = () => {}, 
}) => {

  const defaultCardHolderStyle = {
    height: "25rem",
  }
  const [cardNumber, setCardNumber] = useState(0);
  const [cardsInDisplay, setCardsInDisplay] = useState([-1, 0, 1] );
  const [cardHolderStyle, setCardHolderStyle] = useState(defaultCardHolderStyle)
  const [isOverFlow, setIsOverFlow] = useState("");
  const history = useHistory();
  let { path, url } = useRouteMatch();

  useEffect(() => {
      history.push(url + "/" + cardNumber, {"replace": true});
  }, [cardNumber]);

  const handleHeightChange = (height) => {
    if (height !== "100%" && height !== undefined) {
      if (height > remInPixels(defaultCardHolderStyle.height)) {
        setCardHolderStyle({
          height: height + "px",
        })
      }
      else {
        setCardHolderStyle(defaultCardHolderStyle)
      }
    }
    else {
      setCardHolderStyle(defaultCardHolderStyle)
    }
  }

  const placeFillerCard = (
    <BaseFlashcard
      className="FlashcardArrayWrapper__empty"
      width="100%"
      backText=""
      frontText=""
      pageNumber={0}
      onHeightChange={handleHeightChange}
    />
  );


  const cardsList = cards.map((card, index) => (
    <BaseFlashcard
      index={index}
      key={card.id}
      frontCardStyle={{"fontStyle":"italic"}}
      frontText={card.frontText}
      backText={card.backText}
      onCardFlip={(state) => {
        onCardFlip(card.id, index, state);
        setIsOverFlow("hidden");
        setTimeout(() => {
          setIsOverFlow("");
        }, 3);
      }}
      height={"100%"}
      onHeightChange={handleHeightChange}
      pageNumber={card.pageNumber}
      active={index === cardNumber}
    />
  ));

  const numberOfCards =
    cardsList.length !== undefined ? cardsList.length - 1 : 0;

  const nextCard = useCallback(() => {
    const currentCardNumber =
      cardNumber + 1 < numberOfCards ? cardNumber + 1 : numberOfCards;
    if (currentCardNumber === numberOfCards) {
      onLastFlashcard()
    }

    if (currentCardNumber < numberOfCards) {
      setIsOverFlow("hidden");
      setTimeout(() => {
        setIsOverFlow("");
      }, 90);
    }
    setCardNumber(currentCardNumber);
    setCardsInDisplay(
      currentCardNumber < numberOfCards
        ? [currentCardNumber - 1, currentCardNumber, currentCardNumber + 1]
        : [numberOfCards - 1, numberOfCards, -1]
    );

    onCardChange(cards[currentCardNumber].id, currentCardNumber + 1);
  }, [cardNumber, numberOfCards]);

  const prevCard = useCallback(() => {
    const currentCardNumber = cardNumber - 1 >= 0 ? cardNumber - 1 : 0;
    if (currentCardNumber === numberOfCards) {
      onLastFlashcard()
    }

    if (currentCardNumber !== 0) {
      setIsOverFlow("hidden");
      setTimeout(() => {
        setIsOverFlow("");
      }, 90);
    }

    setCardNumber(currentCardNumber);
    setCardsInDisplay(
      currentCardNumber === 0
        ? [-1, 0, 1]
        : [currentCardNumber - 1, currentCardNumber, currentCardNumber + 1]
    );
    onCardChange(cards[currentCardNumber].id, currentCardNumber + 1);
  }, [cardNumber, numberOfCards]);


  return (
    <div className="FlashcardArrayWrapper" style={FlashcardArrayStyle}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LinearProgress variant="determinate" value={(cardNumber + 1) / cardsList.length * 100} style={{"height":"1rem", "borderRadius":"1rem"}} />
        </Grid>
        <Grid item xs={12}>
          <div
            className="FlashcardArrayWrapper__CardHolder"
            style={cardHolderStyle}
          >
            {cardsInDisplay[0] !== -1
              ? cardsList[cardsInDisplay[0]]
              : placeFillerCard}
            {cardsList[cardsInDisplay[1]]}
            {cardsInDisplay[2] !== -1
              ? cardsList[cardsInDisplay[2]]
              : placeFillerCard}
          </div>
        </Grid>
        <Grid item xs={12} className={'d-flex align-items-center justify-content-center FlashcardArrayWrapper__controls'}>
            <button onClick={prevCard} style={{"height": "2rem", "width":"2rem"}}>
              <ArrowBackIos />
            </button>
            <span className="fs-4">
              {cardNumber + 1} / {cardsList.length}
            </span>
            <button onClick={nextCard} style={{"height": "2rem", "width":"2rem"}}>
              <ArrowForwardIos />
            </button>
        </Grid>
      </Grid>
    </div>
  );
}

