import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'reactstrap';
import { PrivateTemplate } from '../Templates';

export const AdBlock = (props) => {

    return (
      <PrivateTemplate>
        <div className="container-fluid mt-3">
          <Alert color="warning">
            <FormattedMessage id="message.global.adBlock" />
          </Alert>
        </div>
      </PrivateTemplate>
    );
};
