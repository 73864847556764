import React, { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CircleIcon, InfoIcon } from '../Icons';
import { useSelector } from 'react-redux';

import "./MyCredits.css";
import InviteFriendsButton from '../InviteFriends/InviteFriendsButton';
import ProfileButton from '../Profile/ProfileButton';

const MyCredits = ({ className }) => {

  // Previous data from context
  const user = useSelector((state) => state.user.user);
  const sections = useSelector((state) => state.profile.list);
  const profileReward = useSelector((state) => state.system.credit_policy_level_5);
  const friendsPrintingReward = useSelector((state) => state.system.credit_policy_friends_first_print);
  const friendsAddedReward = useSelector((state) => state.system.credit_policy_friends_first_level);
  const adClickCounter = useSelector((state) => state.user.adClickCounter);

  const filledProfile = sections && sections.length === 0;
  const friendsReward = friendsAddedReward + friendsPrintingReward;

  if (user?.numberOfPrints === 0) {
    return null;
  }

  return (
    <Fragment>
        <div className={["card zc-card my-credits", className].filter(Boolean).join(" ")}>
          <div className="card-body">
            <h4 className='mb-0'>
              <FormattedMessage id="getExtraCredits.title" />
            </h4>
            
            {filledProfile ? null : <div className='d-flex align-items-center justify-content-between w-100 mt-2'>
              <div className='flex-grow-1'><ProfileButton /></div>
              <CreditsText credits={profileReward} />
            </div> }

            <div className='d-flex align-items-center justify-content-between w-100 mt-2'>
              <InviteFriendsButton />
              <CreditsText credits={friendsReward} />
            </div>

            <div className={`d-flex align-items-center justify-content-between w-100 mt-2${adClickCounter.reachedForThisWeek ? ' text-success':''}`}>
              <AdClickCounter />
              <CreditsText credits={adClickCounter.reward} />
            </div>

          </div>
        </div>

    </Fragment>
  );
}

const CreditsText = ({active, disabled, credits, total}) => {

  let className = "text-right my-credits-item-reward";

  if (total === true) {
    className += " ml-auto my-credits-total-reward";
  }

  if (active === false) {
    className += " font-italic";
  }

  return (
    <div className={className} style={ disabled ? {textDecoration: "line-through"} : null }>
      +{credits ? credits : 0 } <FormattedMessage id="global.words.credits" />
    </div>
  );
}

const AdClickCounter = () => {

  const adClickCounter = useSelector((state) => state.user.adClickCounter);

  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);
  const { maxClicks, currentClicks, reward } = adClickCounter;

  const toggle = (e) => {
      setModalOpen(!modalOpen);
      e.preventDefault();
  }

  if (maxClicks === 0) {
      return null;
  }

  let auxCurrentClicks = currentClicks;
  if (maxClicks < currentClicks) {
    auxCurrentClicks = maxClicks;
  }

  let content = (
    <FormattedMessage id="adClickCounter.text" values={{max: maxClicks, current: auxCurrentClicks}} />
  );

  return (
      <div>
          {adClickCounter.reachedForThisWeek ? <CircleIcon checked={true} className="align-sub" /> : null }
          <span className={adClickCounter.reachedForThisWeek ? 'ml-1': ''}>
            <FormattedMessage id="adClickCounter.title" />
          </span>
          <span className='ml-1'>
            {content}
          </span>

          <InfoIcon width="24px" height="24px" style={{cursor: "pointer"}}
                  onClick={toggle} title={intl.formatMessage({id: "adClickCounter.moreInfo"})} className="ml-1 align-sub" />

          <Modal isOpen={modalOpen} toggle={toggle} centered>
              <ModalHeader toggle={toggle} tag="h4">
                  <FormattedMessage id="adClickCounter.title" />
              </ModalHeader>
              <ModalBody>
                  <FormattedMessage id="adClickCounter.description" values={{reward: reward, max: maxClicks, breakLine: (
                    <div className='mt-1' />
                  )}} />
              </ModalBody>
          </Modal>
         
      </div>
  );
}

export default MyCredits;
