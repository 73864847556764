import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { isReadyForDelivery } from '../../shared/utils';
import PrintForFree from './PrintForFree';
import { useGlobal } from '../../contexts/GlobalContext';

const DocumentListButtons = (props) => {

    const docs = useSelector(state => state.documents.list);
    const selected = useSelector(state => state.documents.selected);
    const user = useSelector((state) => state.user.user);
    const [open, setOpen] = useState(false);

    const { features, userCanPrint, hasDocuments } = useGlobal();
    const deliveryServiceFeatureEnabled = features.DELIVERY_SERVICE;

    const togglePrintForFree = () => {
      setOpen(!open);
    }

    const hasSelected = selected.documents.length !== 0;
    const isDeliveryDisabled = deliveryServiceFeatureEnabled === false 
                              || isReadyForDelivery(docs, selected.documents) === false
                              || user?.printCredits <= (selected.pages)
                              || !userCanPrint;


    return (
      <div>
        <div className="d-flex justify-content-md-end align-items-sm-strech justify-content-lg-start flex-column flex-md-row mb-3">
          <button data-test="btn-delivery-request" className="btn btn-primary mr-2"
            onClick={togglePrintForFree} disabled={!hasSelected || isDeliveryDisabled}>
            <FormattedMessage id="documents.btn.order" />
          </button>
        </div>
        <PrintForFree isOpen={open} toggle={togglePrintForFree} />
      </div>
    );
}

export default DocumentListButtons;
