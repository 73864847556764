import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Error } from '../Messages/Error';
import { isReadyForDelivery  } from '../../shared/utils';

const DeliveryWarningMessage = (props) => {

  const docs = useSelector(state => state.documents.list);
  const selected = useSelector(state => state.documents.selected);
  const user = useSelector((state) => state.user.user);

  const intl = useIntl();

  // Calculations
  const hasCreditsToPrint = useMemo(() => selected?.pages !== 0 && selected?.pages <= user?.printCredits, [selected, user]);

  let errorText = null;
  let extraContent = null;

  if (selected?.pages && hasCreditsToPrint === false) {
    errorText = intl.formatMessage({id: "deliveries.request.message.error.noCredits"});
  } else if (isReadyForDelivery(docs, selected?.documents) === false) {
    errorText = intl.formatMessage({id: "deliveries.request.message.warning.notReady"});
  } else {
    return null;
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <Error errorMessage={errorText} extraContent={extraContent}/>
      </div>
    </div>
  );
}

export default DeliveryWarningMessage;
