import React, { Fragment, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import InviteFriends from "./InviteFriends";
import { useDispatch, useSelector } from "react-redux";
import { userCopyLink, userInviteFriends } from "../../store/actions";
import { getUniqueLink } from "../../shared/utils";

import './InviteFriends.css'

const InviteFriendsButton = (props) => {

  const user = useSelector((state) => state.user.user);

  const [open, setOpen] = useState(false);

  const dispatcher = useDispatch();
  const intl = useIntl();

  const toggle = (e) => {
      setOpen(!open);
      e.preventDefault();
  }

  const onSubmitInviteFriend = (model) => {
    dispatcher(userInviteFriends(model.friends, intl));
  }

  const handleCopyLink = () => {
    dispatcher(userCopyLink(getUniqueLink(user.id), intl));
  }

  return (
    <Fragment>
      <Button color="secondary" size="sm" onClick={toggle} data-test="btn-invite-friends">
        <FormattedMessage id="inviteFriends.title" />
      </Button>

      <Modal isOpen={open} toggle={toggle} data-test="modal-invite-friends" id="inviteFriendsInfoModal">
        <ModalHeader toggle={toggle} tag="h4" className={'border-0 border-md-1sm- pb-0 pb-sm-0 pb-md-1'}>
          <FormattedMessage id="inviteFriends.title" />
        </ModalHeader>
        <ModalBody className={'pt-0 pt-sm-0 pt-md-1'}>
          <InviteFriends
            onInviteFriends={onSubmitInviteFriend}
            onCopyLink={handleCopyLink} />
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

export default InviteFriendsButton;
