import React, { useEffect } from 'react';
import { useState } from 'react';
import { createContext, useContext } from 'react';
import GlobalLimitService from '../Services/GlobalLimitService';
import UserService from '../Services/UserService';
import { useSelector } from 'react-redux';
import {DOC_VALID_STATES_FOR_AI} from '../shared/utils';
import { logger } from '../shared/Logger'

const context = createContext(null);

const initialFeaturesStatus = {
  DOCUMENT_UPLOAD: false,
  DELIVERY_SERVICE: false,
  DELIVERY_PREMIUM_SERVICE: false
};

export const GlobalProvider = ({children}) => {

  const [globalLimitCounter, setGlobalLimitCounter] = useState();
  const [printLimitRestricted, setPrintLimitRestricted] = useState(false);
  const [userCanPrint, setUserCanPrint] = useState(true);
  const [userCanUpload, setUserCanUpload] = useState(true);
  const [userHadDocuments, setUserHasDocuments] = useState(false);
  const [askProfile, setAskProfile] = useState(false);
  const [features, setFeatures] = useState(initialFeaturesStatus);
  const [maxPages, setMaxPages] = useState(0);
  const printLimitRestrictedEnabled = useSelector((state) => state.system.restrict_global_prints);
  const restrictConsecutiveUploads = useSelector(state => state.system.system_restrict_consecutive_uploads);
  const maxConsecutiveUploads = useSelector(state => state.system.system_max_consecutive_uploads);
  const docs = useSelector(state => state.documents.list);

  const loadContext = () => {

    if(printLimitRestrictedEnabled !== undefined) {

      GlobalLimitService.getCounterForCurrentMonth().then(response => {
        setGlobalLimitCounter(response.data);
      }).catch(error => {
        setGlobalLimitCounter(null);
        logger.error("Error fetching global limit", error)
      });

      UserService.getConfiguration().then(response => {
        setAskProfile(response.data.profileMandatory);
        setFeatures(response.data.features);
        setPrintLimitRestricted(response.data.restrictPrintLimit);
        setMaxPages(response.data.maxPagesPerOrder)
      }).catch(error => {
        logger.error("Error fetching user configuration", error)
        setAskProfile(false);
        setPrintLimitRestricted(false);
        setFeatures(initialFeaturesStatus);
        setMaxPages(1000);
      });
    }
    
  }

  useEffect(() => {
    setUserHasDocuments(docs !== undefined && docs.length > 0 && docs.findIndex(doc => DOC_VALID_STATES_FOR_AI.includes(doc.status)) !== -1)
  }, [docs])

  useEffect(() => {

    loadContext();

  }, [printLimitRestrictedEnabled]);


  useEffect(() => {
    if (typeof globalLimitCounter !== "undefined" && typeof printLimitRestricted !== "undefined" && typeof printLimitRestrictedEnabled !== "undefined") {

      if (printLimitRestrictedEnabled) {
        setUserCanPrint(!printLimitRestricted);
        setUserCanUpload(!printLimitRestricted)
      } else {
        setUserCanPrint(true);
        setUserCanUpload(true)
      }
    }

  }, [globalLimitCounter, printLimitRestricted]);

  const { Provider } = context;

  return (
    <Provider value={{
      askProfile: askProfile,
      setAskProfile: setAskProfile,
      features: features,
      reloadConfig: loadContext,
      maxPages: maxPages,
      userCanPrint: userCanPrint,
      userCanUpload: userCanUpload,
      maxUploads: restrictConsecutiveUploads ? maxConsecutiveUploads : 0,
      hasDocuments: userHadDocuments,
      userDocs: docs,
      globalLimitCounter: globalLimitCounter
    }}>
      {children}
    </Provider>
  );
};

export const useGlobal = () => useContext(context);
