import React, { useState, useRef, useEffect } from "react";
import { Typography } from "@material-ui/core";
import "./BaseFlashcard.css";
import { FormattedMessage } from "react-intl";

export const BaseFlashcard = ({
  index,
  frontText,
  frontCardStyle,
  frontContentStyle,
  backText,
  backCardStyle,
  backContentStyle,
  className = "",
  style,
  height,
  borderRadius = "1rem",
  pageNumber = 0,
  cardNoLongerVisible = false,
  onHeightChange = () => {},
  onCardFlip = (state = false) => {},
  active
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [cardHeight, setCardHeight] = useState(height)
  const [frontOverflow, setFrontOverflow] = useState(false);
  const [backOverflow, setBackOverflow] = useState(false);
  const frontRef = useRef();
  const backRef = useRef();


  const checkOverflow = () => {
    if (frontRef.current) {
      const { clientHeight, scrollHeight } = frontRef.current;
      setFrontOverflow(clientHeight < scrollHeight);
    }
    if (backRef.current) {
      const { clientHeight, scrollHeight } = backRef.current;
      setBackOverflow(clientHeight < scrollHeight);
    }
  };

  useEffect(() => {

    if (!active) {
      setIsFlipped(false);
    }

  }, [active]);

  useEffect(() => {
    if (cardNoLongerVisible) {
      setCardHeight("100%")
    }

  }, [cardNoLongerVisible])

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  })

  useEffect(() => {
    if (frontOverflow || backOverflow) {
      setCardHeight(Math.max(frontRef.current?.scrollHeight, backRef.current?.scrollHeight) + 65 )
    }
  }, [frontOverflow, backOverflow])

  useEffect(() => {
    if (cardHeight != "100%") {
      onHeightChange(cardHeight)
    }
  }, [cardHeight])


  return (
    <div
      className={`FlashcardWrapper ${className}`}
      style={{
        height: `${ cardHeight } !important`, 
        ...style,
      }}
    >
      <div
        className={`FlashcardWrapper__item ${
          isFlipped ? "FlashcardWrapper__item--flip" : ""
        }`}
        style={{
          height: cardHeight, 
          borderRadius: borderRadius,
        }}
        onClick={() => {
          setIsFlipped(!isFlipped);
          onCardFlip(!isFlipped);
        }}
      >
        <div
          className={`FlashcardWrapper__item--front${isFlipped?" hide":""} ${index == 0 ? "FlashcardWrapper__item--front--first" : ""}`}
          ref={frontRef}
          style={{
            ...frontCardStyle,
            cursor: "pointer",
            height: cardHeight, 
            paddingBottom: index === 0 ? 0 : "1rem",
            paddingLeft: index === 0 ? 0 : "1rem",
            paddingRight: index === 0 ? 0 : "1rem",
            flexDirection: "column",
          }}
        >
          <div style={{display: "flex", height: "100%", justifyItems: "center", alignItems: "center"}}>
            <Typography
              color="textPrimary"
              variant="h4"
              className="FlashcardWrapper__item--content"
              style={{...frontContentStyle, textAlign: "center"}}
            >
              {frontText}
            </Typography>
          </div>
            {index == 0 && 
            <Typography
            className="flip-text"
              variant="h6"
            >
              <FormattedMessage id={"flashcards.flip"}/>
            </Typography>
            }
        </div>
        <div
          ref={backRef}
          className={`FlashcardWrapper__item--back${isFlipped?"":" hide"}`}
          style={{
            ...backCardStyle,
            cursor:  "pointer",
            height: cardHeight, 
          }}
        >
          <Typography
            color="textPrimary"
            variant="h4"
            className="FlashcardWrapper__item--content"
            style={{...backContentStyle, textAlign: "center"}}
          >
            {backText}
          </Typography>
          <Typography
            color="textSecondary"
            variant="h5"
            className="FlashcardWrapper__item--content"
            style={{...backContentStyle,
              position: "absolute",
              bottom: "0.5rem",
              left: "1rem",
            }}
          >
            <FormattedMessage id={"flashcards.page.num"} values={{
              num: pageNumber + 1
            }}/>
          </Typography>
        </div>
      </div>
    </div>
  );
}

