import React from 'react';
import {Helmet} from "react-helmet";
import { Redirect, useParams } from 'react-router-dom';
import { setRefIdentifier } from '../shared/utils';
import { useMixpanel } from '../contexts/Mixpanel';
import { useLocation } from 'react-router-dom';

const Ref = (props) => {

  const location = useLocation();
  const mixpanel = useMixpanel();
  const { ref_id } = useParams();

  if (ref_id !== undefined) {
    const previousId = mixpanel.get_distinct_id();
    mixpanel.reset();
    
    setRefIdentifier(ref_id);

    mixpanel.identify(ref_id);
    mixpanel.reset();
    
    mixpanel.identify(previousId);
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta property="og:url" content={location.pathname} />
      </Helmet>

      <Redirect to="/create-account" />
    </React.Fragment>
  );
}

export default Ref;