import React, { Fragment, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Moment from 'react-moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { calendarStrings, DOC_VALID_STATES_FOR_SELECTION, DOC_VALID_STATES_FOR_SHARING, DOC_VALID_STATES_FOR_PREVIEW, DOC_VALID_STATES_FOR_REPORT_ERROR, DOC_VALID_STATES_FOR_DELETION, DOC_STATES_IN_PROCESS } from '../../shared/utils';
import { documentShape } from '../../Shapes';
import { DocumentStatus } from './DocumentStatus';
import { DocumentPreview } from './DocumentPreview';
import './DocumentItem.css';
import { DocumentDeleteConfirm } from './DocumentDeleteConfirm';

import { deleteDocument, sharedDocumentModalToggle, toggleDocument } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import DocumentProblem from './DocumentProblem';
import { ReportProblemIcon, TrashIcon, ShareIcon, EyeIcon, AiIcon } from '../Icons';
import { useGlobal } from '../../contexts/GlobalContext';

const DocumentItem = ({ doc }) => {

  const [preview, setPreview] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openReportProblem, setOpenReportProblem] = useState(false);

  const isDeleteSubmitting = useSelector(state => state.documents.isDeleteSubmitting);
  const selected = useSelector(state =>  state.documents.selected);
  const sharingEnabled = useSelector(state => state.system.system_enable_shared_documents);
  const user = useSelector((state) => state.user.user);

  const { userCanPrint } = useGlobal();

  const dispatcher = useDispatch();
  const intl = useIntl();

  const isSelected = selected.documents.includes(doc.id);

  const togglePreview = (e) => {

    if (!doc.previewUrl || !DOC_VALID_STATES_FOR_PREVIEW.includes(doc.status)) {
      return;
    }

    setPreview(!preview);
    e.preventDefault();
  }

  const toggleDeleteConfirm = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setOpenDeleteConfirm(!openDeleteConfirm);
  }

  const handleOnSelect = (e) => {
    dispatcher(toggleDocument(doc.id, doc.numberOfPages, doc.numberOfFullPageAds));
  }

  const handleOnReportProblem = (e) => {
    e?.preventDefault();

    setOpenReportProblem(!openReportProblem);
  }

  const handleShareDocumentClick = (e) => {
    e.preventDefault();

    dispatcher(sharedDocumentModalToggle(doc));
  }

  const handleDeleteClick = (e) => {
    e.preventDefault();

    dispatcher(deleteDocument([doc.id], intl));
  }

  let previewContent = null;

  const showPreview = DOC_VALID_STATES_FOR_PREVIEW.includes(doc.status);
  const showShare = sharingEnabled === true && DOC_VALID_STATES_FOR_SHARING.includes(doc.status) && doc.shared === false;
  const showProblem = DOC_VALID_STATES_FOR_REPORT_ERROR.includes(doc.status);
  const showDelete = DOC_VALID_STATES_FOR_DELETION.includes(doc.status);

  if (showPreview) {
    previewContent = (
      <DocumentPreview url={doc.previewUrl} isOpen={preview} toggle={togglePreview}/>
    );
  }

  const docStatus = <DocumentStatus status={doc.status} />


  return (
    <tr className='d-flex align-content-start justify-content-between'>
      <td>
        <div>
        {DOC_VALID_STATES_FOR_SELECTION.includes(doc.status) ?
          <input type="checkbox" className="big-1" onClick={handleOnSelect}
            disabled={!userCanPrint}
            checked={isSelected} onChange={e => {}} /> : null }
        </div>
      </td>
      <td className='p-1 d-flex flex-grow-1'>
        <div className='d-flex flex-column flex-md-row'>
          <div className='flex-grow-1'>
            <div className="text-break doc-name" title={doc.name}>
              <span onClick={togglePreview} style={preview !== null?{cursor: "pointer"}:null}>{doc.name}</span>
            </div>
            <div>
              <span className="text-muted small">
                <Moment utc local parse="DD/MM/YYYY HH:mm" calendar={{...calendarStrings, sameDay: intl.formatMessage({id: "calendar.sameDay"})}}>{doc.uploadDate}</Moment>
                <DocumentPages doc={doc} />
              </span>
            </div>
          </div>
          <div className='sm-screen mt-md-0 mt-2 flex-fill-1'>
            {docStatus}
          </div>
        </div>
      </td>
      <td className='d-none d-md-table-cell'>
        {docStatus}
      </td>
      <td>
        <DocumentItemIcons
            intl={intl}
            showPreview={showPreview}
            onPreviewClick={togglePreview}
            showShare={showShare}
            onShareClick={handleShareDocumentClick}
            showProblem={showProblem}
            onProblemClick={handleOnReportProblem}
            showDelete={showDelete}
            onDeleteClick={toggleDeleteConfirm}
          />
        {previewContent}

        <DocumentDeleteConfirm
          isOpen={openDeleteConfirm}
          toggle={toggleDeleteConfirm}
          onDelete={handleDeleteClick}
          isSubmitting={isDeleteSubmitting}/>

        <DocumentProblem doc={doc} isOpen={openReportProblem} toggle={handleOnReportProblem} />
      </td>
    </tr>
  );
}

DocumentItem.propTypes = {
  doc: documentShape.isRequired
}

export default DocumentItem;

const DocumentPages = ({doc}) => {

  if (doc?.numberOfPages > 0) {

    let fullPageAdsContent;

    if (doc.numberOfFullPageAds > 0) {
      fullPageAdsContent = (
        <Fragment>
          &nbsp;+&nbsp;
          <FormattedMessage id="global.words.fullPageAds" values={{ads: doc.numberOfFullPageAds}}/>
        </Fragment>
      );
    }

    return (
      <Fragment>
          {" - "}
          <span className='text-lowercase'><FormattedMessage id="global.words.pages" values={{pages: doc.numberOfPages}}/></span>
          {fullPageAdsContent}
      </Fragment>
    );

  }

  return null;

}

const DocumentItemIcons = ({intl, showPreview, onPreviewClick, showShare, onShareClick, showProblem, onProblemClick, onDeleteClick, showDelete}) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = (e) => {
    setIsOpen(!isOpen);
    e.preventDefault();
  }

  const previewClick = (e) => {
    toggle(e);
    onPreviewClick(e);
  }

  const shareClick = (e) => {
    toggle(e);
    onShareClick(e);
  }

  const problemClick = (e) => {
    toggle(e);
    onProblemClick(e);
  }

  const deleteClick = (e) => {
    toggle(e);
    onDeleteClick(e);
  }

  const previewButtonAsLink = (
    <a href="#preview" onClick={onPreviewClick} title={intl.formatMessage({"id": "documents.item.btn.preview"})} className="mr-1">
      <EyeIcon className="doc-icon icon" />
    </a>
  );

  const previewButtonAsMenuItem = (
    <div className='menu-item' onClick={previewClick} title={intl.formatMessage({"id": "documents.item.btn.preview"})}>
      <EyeIcon className="doc-icon icon" />
      <span className='text'><FormattedMessage id="documents.item.btn.preview" /></span>
    </div>
  );

  const shareButtonAsLink = (
    <a href="#share" onClick={onShareClick} title={intl.formatMessage({"id": "documents.item.btn.shareDocument"})} className="mr-1" data-test="link-to-share-document">
      <ShareIcon className="doc-icon icon" />
    </a>
  );

  const shareButtonAsMenuItem = (
    <div className='menu-item' onClick={shareClick} title={intl.formatMessage({"id": "documents.item.btn.shareDocument"})} data-test="menu-item-to-share-document">
      <ShareIcon className="doc-icon icon" />
      <span className='text'><FormattedMessage id="documents.item.btn.shareDocument" /></span>
    </div>
  );

  const problemButtonAsLink = (
    <a href="#problem" onClick={onProblemClick} title={intl.formatMessage({id: 'documents.item.btn.problem'})} className="mr-1">
      <ReportProblemIcon className="doc-icon icon" />
    </a>
  );

  const problemButtonAsMenuItem = (
    <div className='menu-item' onClick={problemClick} title={intl.formatMessage({id: 'documents.item.btn.problem'})}>
      <ReportProblemIcon className="doc-icon icon" />
      <span className='text'><FormattedMessage id="documents.item.btn.problem" /></span>
    </div>
  );

  const deletemButtonAsLink = (
    <a href="#delete" onClick={onDeleteClick} title={intl.formatMessage({id: 'documents.item.btn.delete'})} data-test="link-to-delete-document">
      <TrashIcon className="doc-icon icon" />
    </a>
  );

  const deleteButtonAsMenuItem = (
    <div className='menu-item' onClick={deleteClick} title={intl.formatMessage({id: 'documents.item.btn.delete'})} data-test="menu-item-to-delete-document">
      <TrashIcon className="doc-icon icon" />
      <span className='text'><FormattedMessage id="documents.item.btn.delete" /></span>
    </div>
  );

  const modal = (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-menu" fade={false}>
      <ModalBody>
        {showPreview ? previewButtonAsMenuItem : null}
        {showShare ? shareButtonAsMenuItem : null}
        {showProblem ? problemButtonAsMenuItem : null}
        {showDelete ? deleteButtonAsMenuItem : null}
      </ModalBody>
    </Modal>
  );

  return (
    <Fragment>
      <div className='d-none d-sm-flex algin-items-center justify-content-end'>
        {showPreview ? previewButtonAsLink : null}
        {showShare ? shareButtonAsLink : null}
        {showProblem ? problemButtonAsLink : null}
        {showDelete ? deletemButtonAsLink : null}
      </div>

      <div className='d-block d-sm-none'>
        <i className="fas fa-ellipsis-v" onClick={toggle}></i>
        {modal}
      </div>
    </Fragment>
  );

};

