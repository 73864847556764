import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ConfigurationService, { DAILY_FREE_PAGES } from "../../Services/ConfigurationService";

const UnlimitedPrintingWithDeliveries = () => {

  const [open, setOpen] = useState(false);
  const [customParameters, setCustomParameters] = useState({
    breakLine: (<div className='mt-1' />),
    breakLine2: (<div className='mt-2' />),
    breakLine3: (<div className='mt-3' />),
    b: (text) => (<strong>{text}</strong>),
    bigFont: (text) => (<span className="fs-5">{text}</span>)
  });

  useEffect(() => {
    
      ConfigurationService.getConfiguration(DAILY_FREE_PAGES)
      .then(response => {
        const newParams = {
          "pages": response.data.value
        };
        setCustomParameters(prev => {
          return {...prev, ...newParams}
        });
      }).catch(error => {
        // Fallback to default value
        const newParams = {
          "pages": 50
        };
        setCustomParameters(prev => {
          return {...prev, ...newParams}
        });
      });
    
  }, []);

  const toggle = (e) => {
    e.preventDefault();

    setOpen(prev => !prev);
  }

  return (
    <>
      <span>
        <FormattedMessage id="temp.notification.print_all_you_can_december_2024.extra_message" />&nbsp;
        <a href="#more-info-unlimited-printing-deliveries" onClick={toggle}>
          <FormattedMessage id="temp.notification.print_all_you_can_december_2024.extra_message.moreInfo" />
        </a>
      </span>
      <Modal isOpen={open} toggle={toggle} centered>
        <ModalHeader>
          <FormattedMessage id="temp.notification.print_all_you_can_december_2024.title" />
        </ModalHeader>
        <ModalBody>
          <FormattedMessage id="temp.notification.print_all_you_can_december_2024.content" values={customParameters}/>
        </ModalBody>
        <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          <FormattedMessage id="btn.close" />
        </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default UnlimitedPrintingWithDeliveries;