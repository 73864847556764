import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { UncontrolledPopover, PopoverBody, Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap';
import { sharedDocumentShape } from '../../Shapes';
import { FormattedMessage } from 'react-intl';
import { DocumentIcon } from '../Icons';

import { success as successNotification, error as errorNotification } from 'react-notification-system-redux';

import { useDispatch, useSelector } from 'react-redux';
import SharedDocumentsService from '../../Services/SharedDocumentsService';
import { globalErrorHandling } from '../../shared/utils';
import { sharedDocumentsFetch } from '../../store/actions';

import './SharedDocumentItem.css';

const SharedDocumentItem = ({ item, lang, intl, onCopyClick, onPreviewClick, disableSharing }) => {

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const user = useSelector((state) => state.user.user);
  const dispatcher = useDispatch();

  let showDeleteButton = item?.sharedBy === user?.id;

  const onCopy = (e) => {
      e.preventDefault();

      onCopyClick(item);
  };

  const onPreview = (e) => {
      e.preventDefault();

      onPreviewClick(item);
  };

  const onDelete = (e) => {
    if (deleting === true) {
      return;
    }
    
    e.preventDefault();
    setShowDeleteConfirm(!showDeleteConfirm)

  }

  const onConfirmDelete = (e) => {

    if (deleting === true) {
      return;
    }

    e.preventDefault();

    setDeleting(true);

    SharedDocumentsService.delete(item.id).then(response => {

      dispatcher(sharedDocumentsFetch());

      dispatcher(successNotification({
        message: intl.formatMessage({ id: "sharedDocuments.delete.msg.success"}),
        position: 'tc',
        autoDismiss: 0,
      }));

    }).catch(error => {

      const errorObj = globalErrorHandling(error);

      let msg = intl.formatMessage({"id": errorObj.errorKey}, errorObj.errorParams);
      if (msg === errorObj.errorKey) {
        msg = intl.formatMessage({ id: "sharedDocuments.delete.msg.error"});
      }
      
      dispatcher(errorNotification({
        message: msg,
        position: 'tc',
        autoDismiss: 0,
      }));

    }).finally(() => {
      setShowDeleteConfirm(false);
      setDeletting(false);
    });

  }

    return (
        <div className="sharedDocument-item mb-2 border border-secondary rounded p-2 d-flex align-items-center justify-content-between">

            <div className='d-flex align-items-center'>
              
              <div className="mr-3">
                  <DocumentIcon size="4rem" type={item.type} />
              </div>

              <div className=''>
                  <a href="#preview" className="d-flex flex-wrap align-items-top" title={item.name} onClick={onPreview}>
                      <span className='shared-doc-name text-break'>{item.name}</span>
                      <span className="align-self-start ml-2 badge badge-secondary">
                          <FormattedMessage id={`sharedDocuments.item.field.category.${item.category}`} />
                      </span>
                  </a>

                  <div>{item.description}</div>
                  
                  <div>
                      <small>
                          <span className="mr-2 text-lowercase" title={intl.formatMessage({id: "sharedDocuments.item.field.pages"})}>
                              <i className="far fa-file-alt"></i>&nbsp;
                              <FormattedMessage id="global.words.pages" values={{pages: item.pages}}/>
                          </span>

                          <span className="mr-2" title={intl.formatMessage({id: "sharedDocuments.item.field.created"})}>
                              <i className="far fa-calendar-alt"></i>&nbsp;
                              <Moment fromNow locale={lang} parse="DD/MM/YYYY h:mm:ss">{item.created}</Moment>
                          </span>

                          <span title={intl.formatMessage({id: "sharedDocuments.item.field.sharedTimes"})}>
                              <i className="fas fa-cloud-download-alt"></i>&nbsp;
                              {item.sharedTimes}
                          </span>
                      </small>
                  </div>
              </div>
            </div>

            <div>
                
                <button id={`copy-doc-${item.id}`} className="btn p-0" onClick={onCopy} disabled={disableSharing}>
                    <i className="far fa-copy" style={{fontSize: "2.5rem"}}></i>
                </button>

                { showDeleteButton ? <Fragment><ConfirmDelete isOpen={showDeleteConfirm} isSubmitting={deleting} onAccept={onConfirmDelete} onCancel={onDelete} /><button className="ml-1 btn p-0" onClick={onDelete}>
                    <i className="fas fa-trash-alt" style={{fontSize: "2.5rem"}}></i>
                </button></Fragment> : null }

                <UncontrolledPopover trigger="hover" placement="bottom" target={`copy-doc-${item.id}`}>
                    <PopoverBody>
                        <FormattedMessage id="sharedDocuments.item.btn.copy" />
                    </PopoverBody>
                </UncontrolledPopover>
            </div>

        </div>
    );
}

SharedDocumentItem.propTypes = {
    item: sharedDocumentShape.isRequired,
    lang: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
    onCopyClick: PropTypes.func.isRequired,
    onPreviewClick: PropTypes.func.isRequired,
    disableSharing: PropTypes.bool.isRequired
}

export default SharedDocumentItem;

const ConfirmDelete = ({isOpen, onAccept, onCancel, isSubmitting}) => {

  return (
    <Modal isOpen={isOpen} toggle={onCancel}>
      <ModalHeader toggle={onCancel} tag="h4">
        <FormattedMessage id="confirm.title" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id="sharedDocuments.delete.confirm" />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onAccept} disabled={isSubmitting}>
          {isSubmitting ? <Spinner size="sm" className="ml-2" /> : null }
          <FormattedMessage id="confirm.btn.ok" />
        </Button>
        <Button onClick={onCancel} disabled={isSubmitting}>
          <FormattedMessage id="btn.cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};