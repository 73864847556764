import { emitter } from "@marvelapp/react-ab-test";
import { EXPERIMENT_STARTED, EXPERIMENT_WIN } from "./shared/EventsNames";

export const configureExperiments = (mixpanel) => {

  emitter.defineVariants('AI Call to Action', ['toolbar', 'document-list', 'document-list-item']); // Nono of the variants win, menu option is better

  // Called when the experiment is displayed to the user.
  emitter.addPlayListener(function(experimentName, variantName) {
      mixpanel.track(EXPERIMENT_STARTED, {
          "Experiment name": experimentName,
          "Variant name": variantName,
        });
  });

  // Called when a 'win' is emitted, in this case by emitter.emitWin('My Example')
  emitter.addWinListener(function(experimentName, variantName) {
    mixpanel.track(EXPERIMENT_WIN, {
        "Experiment name": experimentName,
        "Variant name": variantName,
    });
  });

};
