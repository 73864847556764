import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { documentShape } from '../../Shapes';
import { Button, Col, Row } from 'reactstrap';

const RequestSummary = ({documents, docsToShow = 3}) => {

  const [showAllDocs, setShowAllDocs] = useState(false);

  if (documents === undefined || documents === null || documents.length === 0) {
    return null;
  }

  let content = documents.map((doc, index) =>
      <Row key={index} className={`${index === 0 ? "pt-1 pb-1": "pb-1"}${index > docsToShow - 1 && showAllDocs !== true ? " d-none": ""}`}>
        <Col xs={7}>
          <div className="text-wrap" title={doc.name}>{doc.name}</div>
        </Col>
        <Col className='d-flex align-items-end'>
          <div>
            <FormattedMessage id="global.words.pages" values={{pages: doc.numberOfPages}} />
            {doc.numberOfFullPageAds !== 0?(<Fragment>&nbsp;+&nbsp;<FormattedMessage id="global.words.fullPageAds" values={{ads: doc.numberOfFullPageAds}} /></Fragment>):null}
          </div>
        </Col>
      </Row>
    );

  return (
    <div className='documents-overview'>
      {content}
      {documents.length > docsToShow ? 
        <div className='d-flex justify-content-center mt-2 mb-2'>
          <Button size="xs" outline onClick={e => setShowAllDocs(!showAllDocs)}><FormattedMessage id={showAllDocs === false ? "deliveries.request.overview.documents.btn.showMore" :"deliveries.request.overview.documents.btn.showLess"} /></Button>
        </div> : null}
    </div>
  );

};

RequestSummary.propTypes = {
  documents: PropTypes.arrayOf(documentShape).isRequired
}

export default RequestSummary;
