import React, { useEffect, useState } from 'react';
import { AiContentTableSection } from './AiContentTableSection';
import FlashcardService from '../Services/FlashcardService';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import CustomSpinner from '../../Components/Commons/Spinner'
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { AiContentConfirmDelete } from './AiContentConfirmDelete';
import { logger } from '../../shared/Logger';
import { AI_FLASHCARDS, AI_QUIZ } from '../Ai/Containers/AiTutorPage';
import QuestionService from '../Services/QuestionService';
import { getColumns } from './TableColumns';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import { PlusIcon } from '../../Components/Icons';


export const AiContentTable = ({type}) => {
  
  const history = useHistory();
  const location = useLocation();
  const [dataMap, setDataMap] = useState({})
  const [loading, setLoading] = useState(false);
  const [aiContent, setAiContent] = useState([]);
  const [ openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const [ toDeleteDoc, setToDeleteDoc ] = useState(null)
  const [ toDeleteId, setToDeleteId ] = useState(null)
  const [ confirmDeleteId, setConfirmDeleteId ] = useState(null);
  const intl = useIntl();
  const lang = intl.locale;

  let create_url = "/ai/flashcards-generator";
  if (AI_QUIZ === type) {
    create_url = "/ai/quiz-generator";
  }

  useEffect(() => {

    loadData();

  }, [type]);

  useEffect(() => {
    const data = {}
    
    aiContent.forEach(aiContent => {
      const docName = aiContent.documentName
      if (!Object.hasOwn(data, docName)) {
        data[docName] = []
      }
      // FIXME sort by documentName //
      data[docName].push({
        id: aiContent.id,
        name: aiContent.name,
        number: aiContent.status !== "GENERATED" || !aiContent.content ? 0 : aiContent.content.length,
        startPage: aiContent.startPage,
        endPage: aiContent.endPage,
        status: aiContent.status,
        timesUsed: 0,
        created: moment.utc(aiContent.created, "DD-MM-YYYY HH:mm:ss"),
        document: aiContent.documentName
      })
    });
    Object.keys(data).forEach(doc => {
      data[doc].sort((a, b) => {
        if (a.created.isBefore(b.created)) {
          return 1
        }
        else if (b.created.isBefore(a.created)) {
          return -1
        }
        return 0
      })
    });
    setDataMap(data)

  }, [aiContent])

  const loadData = () => {

    setLoading(true);

    if (type == AI_FLASHCARDS) {
      FlashcardService.fetchAll()
        .then(response => {
          setAiContent(response.data);
        }).catch(error => {
          logger.error("Error fetching all flashcards", error)
        }).finally(() => {
          setLoading(false);
        });
    }
    else if (type == AI_QUIZ) {
      QuestionService.fetchAll()
        .then(response => {
          setAiContent(response.data);
        }).catch(error => {
          logger.error("Error fetching quiz's", error)
        }).finally(() => {
          setLoading(false);
        });
    }
  }

  const handleDelete = (id) => {
    setOpenDeleteConfirm(false)

    if (type == AI_FLASHCARDS) {
      FlashcardService.delete(id).then(() => {
        setConfirmDeleteId(id)
      }).catch(error => {
          logger.error("Error deleting flashcard with id:" + id, error)
      }).finally(() => {
        setTimeout(() => setConfirmDeleteId(null), 400)
      })
    }
    else if (type == AI_QUIZ) {
      QuestionService.delete(id).then(() => {
        setConfirmDeleteId(id)
      }).catch(error => {
          logger.error("Error deleting quiz with id:" + id, error)
      }).finally(() => {
        setTimeout(() => setConfirmDeleteId(null), 400)
      })
    }
  }
  
  const handleSelect = (id) => {
    history.push(`/ai/${type}/${id}`)
  }

  const toggleDeleteConfirm = (aiContent) => {
    if (!openDeleteConfirm) {
      setToDeleteId(aiContent.id);
      setToDeleteDoc(aiContent.document);
      setOpenDeleteConfirm(true);
    }
    else {
      setToDeleteId(null);
      setToDeleteDoc(null);
      setOpenDeleteConfirm(false);
    }
  }

  const markDeleted = (id) => {
    setConfirmDeleteId(null)
    setToDeleteId(null);
    setToDeleteDoc(null);
    setAiContent(aiContent.filter(card => card.id !== id))

  }

  const handleAdd = () => {
    history.push(create_url, { previous: location.pathname});
  }

  let content = null;
  const keys = Object.keys(dataMap);

  if (loading) {
    content = (
      <div className='text-center'>
        <CustomSpinner messageKey="ai.table.loading" />
      </div>
    );
  } else if (keys.length === 0) {
    content = (
      <div>
        <FormattedMessage id={`${type}.table.empty`} values={{
          a: text => <Link to={create_url}>{text}</Link>
        }} />
      </div>
    );
  } else {
    content = (
      keys.map(doc => <AiContentTableSection key={doc} doc={doc} aiContent={dataMap[doc]} columns={getColumns(type, lang)} onDelete={toggleDeleteConfirm} onSelect={handleSelect} deletedId={confirmDeleteId} markDeleted={markDeleted}/> )
    );
  }
  
  return (
    <>
      <div className='d-flex align-items-center justify-content-between pb-2 border-bottom mb-3'>
        <h4 className='mb-0' data-test="ai-flashcards-title"><FormattedMessage id={`${type}.table.title`}/></h4>
        <Button color='secondary' size='sm' onClick={handleAdd}>
          <PlusIcon />
          <span>
            <FormattedMessage id={`${type}.table.buttons.add`} />
          </span>
        </Button>
      </div>
      { content }
      <AiContentConfirmDelete open={openDeleteConfirm} docName={toDeleteDoc} cardId={toDeleteId} handleConfirm={handleDelete} handleClose={toggleDeleteConfirm} type={type} />
    </>
  );
}


