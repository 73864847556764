import { put, select } from 'redux-saga/effects';
import api from "../../Api";
import { globalErrorHandling } from '../../shared/utils';
import { getToken } from './selectors';
import { success as successNotification, error as errorNotification } from 'react-notification-system-redux';
import { DeliveryService, DocumentService } from '../../Services';

import * as actions from "../actions";

export function* documentsFetchSaga() {
  try {
    const token = yield select(getToken);
    const response = yield DocumentService.fetchAll(token);
    yield response.data.forEach(doc => {
      doc['loaded'] = (doc.status === 'IN_PROCESS') ? new Date() : null;
    });
    yield put(actions.fetchDocumentsSuccess(response.data));
  } catch(error) {
    yield put(actions.fetchDocumentsError(error));
  }
}

export function* documentDeleteSaga(action) {

    let successMessageKey = "documents.remove.message.success";
    let errorMessageKey = "documents.remove.message.success";
    if (action.documentIds.length > 1) {
      successMessageKey += ".plural";
      errorMessageKey += ".plural";
    }

    try {
      const token = yield select(getToken);
        yield DocumentService.delete(token, action.documentIds);

        yield put(actions.deleteDocumentSuccess(action.documentIds));
       
        yield put(successNotification({
          message: action.intl.formatMessage({ id: successMessageKey}),
          position: 'tc',
          autoDismiss: 5,
        }));

    } catch (error) {
        const errorObj = globalErrorHandling(error, errorMessageKey);
        yield put(actions.deleteDocumentError(errorObj));

        yield put(errorNotification({
          message: action.intl.formatMessage({ id: errorObj.errorKey}),
          position: 'tc',
          autoDismiss: 0,
        }));
    }
}

export function* documentUploadSaga(action) {
  try {
    const formData = new FormData();
    formData.append('document', action.file);
    let percentCompleted = 0;
    const token = yield select(getToken);
    const response = yield api.post('/v1/documents', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-AUTH-TOKEN':  token
      },
      onUploadProgress: function(progressEvent) {
        percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      }
    });

    if(percentCompleted === 100) {

      yield put(actions.uploadDocumentSuccess());
      yield put(actions.fetchDocuments());

    }

  } catch (error) {
    const errorObj = globalErrorHandling(error, "documents.upload.message.error");
    yield put(actions.uploadDocumentError(errorObj));

    if (errorObj.errorCode && errorObj.errorCode === 21) {
      yield put(actions.metadataFetch("system_enable_upload", "boolean"))
    } else {
      yield put(errorNotification({
        message: action.intl.formatMessage({ id: errorObj.errorKey}),
        position: 'tc',
        autoDismiss: 0,
      }));
    }
  }
}

export function* deliveryRequestSaga(action) {

  const request = action.request;

  try {

    const token = yield select(getToken);
    yield api.post("/v1/prints/request", {"documentIds": request.documents}, {headers: {'X-AUTH-TOKEN': token}});
    const response = yield DeliveryService.request(request);

    if (response.data.stripeResult) {

      if (request.paymentType === "BANCONTACT") {
        window.location = response.data.stripeResult;
      } else {
        window.stripeApi.redirectToCheckout({
          sessionId: response.data.stripeResult
        }).then((result) => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          console.log(result)
        });
      }

      

    } else {
      yield put(actions.deliveryRequestSuccess(response.data));
      yield put(actions.fetchUser());
    }
    
  } catch(error) {
    const errorObj = globalErrorHandling(error);
    yield put(actions.deliveryRequestError(errorObj));

    if (errorObj.errorCode && (errorObj.errorCode === 301 || errorObj.errorCode === 303)) {
      yield put(actions.metadataFetch("system_enable_home_delivery", "boolean"))
    }
  }
}
