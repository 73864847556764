import React, { Fragment, useState } from "react";
import { Button } from "reactstrap";
import SectionList from "./SectionList";
import { fetchUser, profileFetch } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useGlobal } from "../../contexts/GlobalContext";
import { ProfileService } from "../../Services";
import { getToken, globalErrorHandling } from "../../shared/utils";

const ProfileButton = ({buttonTextId = "mycredits.weekly.profile.fill_button", buttonType = "secondary"}) => {

  const sections = useSelector((state) => state.profile.list);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState();

  const dispatcher = useDispatch();

  const filledProfile = sections && sections.length === 0;
  const { setAskProfile } = useGlobal(); 

  if (filledProfile) {
    return null;
  }

  const handleProfileToggle = (e) => {
    e.preventDefault();
    setOpen(!open);
  }

  const handleSave = (sectionId, fieldValues, isFinalStep) => {

    setSubmitting(true);

    ProfileService.save(getToken(), sectionId, fieldValues).then(response => {

      if(isFinalStep) {
        setAskProfile(false);
        dispatcher(profileFetch());
        dispatcher(fetchUser());
        setOpen(false);
      } else {
        setCurrentIndex(currentIndex + 1);
      }

    }).catch(error => {
      const errorObj = globalErrorHandling(error);
      setError(errorObj);
    }).finally(() => {
      setSubmitting(false);
    });

  }

  return (
    <Fragment>
      <Button color={buttonType} size="sm" onClick={handleProfileToggle} data-test="btn-to-fill-profile">
          <FormattedMessage id={buttonTextId} />
        </Button>
      <SectionList opened={open} onSave={handleSave} loading={loading} submitting={submitting} currentIndex={currentIndex} error={error} />
    </Fragment>
  );
}

export default ProfileButton;
