import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import webSocketMiddleware from './store/WebSocketMiddelware';
import { composeWithDevTools } from "redux-devtools-extension";
import { getToken, hasLocalStorage, ANALYTICS_PROP_VIA } from './shared/utils';

import mixpanel from 'mixpanel-browser';
import MixpanelProvider from 'react-mixpanel';
import MixpanelCustomProvider from './Components/Commons/MixpanelCustomProvider';

import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/nl';

import { localeSet, directLogIn } from "./store/actions";

import rootReducer from "./store/reducers";
import {
  watchUser, watchDocument, watchLocation, watchSecurity,
  watchAddress, watchProfile, watchAds, watchSystem,
  watchGlobal, watchConsent, watchLeaderboard, watchSharedDocuments
} from "./store/sagas";
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';

import TagManager from 'react-gtm-module'
import { setupInterceptors } from './Api';
import { setupInterceptors as setupInterceptorsAiTutor } from './AiTutor/ApiForTutor';
import { setupInterceptors as setupInterceptorsAiTopics} from './AiTutor/ApiForTopics';
import { marketingLanguage } from './MarketingLoader';
import { CompetitionProvider } from './Competition/CompetitionContext';
import { languages } from './config';
import { configureExperiments } from './Experiments';

import { logger } from './shared/Logger';
import { UNSUPPORTED_LANGUAGE } from './shared/EventsNames';
 
if (process.env.REACT_APP_GOOGLE_TAG_MANAGER) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER
  };
 
  TagManager.initialize(tagManagerArgs);
}


(function () {

  if ( typeof window.CustomEvent === "function" ) return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

if (process.env.REACT_APP_LOGENTRIES_TOKEN) {
  logger.init(process.env.REACT_APP_LOGENTRIES_TOKEN);
}

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
mixpanel.register({
  Via: ANALYTICS_PROP_VIA,
  app_version: process.env.REACT_APP_VERSION
});

configureExperiments(mixpanel);

const composeEnhancers = process.env.NODE_ENV === 'development'
  ? composeWithDevTools
  : compose || null;

/**
 * Redux Saga
 */
const sagaMiddleware = createSagaMiddleware({
  context: {
    mixpanel: mixpanel
  }
});

/**
 * Store with reducers and Redux Saga
 */
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, webSocketMiddleware))
);


/**
 * Redux Saga watchers
 */
sagaMiddleware.run(watchUser);
sagaMiddleware.run(watchDocument);
sagaMiddleware.run(watchLocation);
sagaMiddleware.run(watchSecurity);
sagaMiddleware.run(watchAddress);
sagaMiddleware.run(watchProfile);
sagaMiddleware.run(watchAds);
sagaMiddleware.run(watchSystem);
sagaMiddleware.run(watchGlobal);
sagaMiddleware.run(watchConsent);
sagaMiddleware.run(watchLeaderboard);
sagaMiddleware.run(watchSharedDocuments);

const token = getToken();

if (token !== undefined && token !== null && token !== "") {
    store.dispatch(directLogIn(token));
}

let selectedLang;

if (hasLocalStorage()) {
  selectedLang = localStorage.getItem("lang");
}

if (selectedLang) {
  store.dispatch(localeSet(marketingLanguage(selectedLang)));
} else {

  const language = (navigator.languages && navigator.languages[0]) ||
                       navigator.language ||
                       navigator.userLanguage;

  const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

  const newLanguage = marketingLanguage(languageWithoutRegionCode);
  let lang = languages.find(l => l === newLanguage);
  
  if (!lang) {
    mixpanel.track(UNSUPPORTED_LANGUAGE, {"language": lang});
    lang = "en";
  }

  store.dispatch(localeSet(lang));
}

ReactDOM.render((
    <BrowserRouter>
        <Provider store={store}>
          <MixpanelCustomProvider mixpanel={mixpanel}>
            <MixpanelProvider mixpanel={mixpanel}>
              <CompetitionProvider>
                <Route component={App} />
              </CompetitionProvider>
            </MixpanelProvider>
          </MixpanelCustomProvider>
        </Provider>
    </BrowserRouter>
  ), document.getElementById('root'));

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for(let registration of registrations) {
          registration.unregister();
      } 
  });
}

setupInterceptors(store);
setupInterceptorsAiTutor(store);
setupInterceptorsAiTopics(store);

